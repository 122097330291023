import React from 'react'
import styles from './notification.module.scss'
import { Flex } from '@/_yc'
import useToken from '@/_helpers/Hooks/useToken'
import axios from 'axios'

import { useTranslation } from 'react-i18next'

export interface NotificationProps {
    id: number
    message: string
    link?: string
    onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Notification: React.FC<NotificationProps> = ({ message, link, onClose }) => {
    const token = useToken()
    const { t } = useTranslation()

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}${link as string}`, {
                responseType: 'blob',
                headers: token ? { Authorization: `Token ${token}` } : {},
            })
            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const downloadUrl = URL.createObjectURL(blob)

            const tagLink = document.createElement('a')
            tagLink.href = downloadUrl
            tagLink.download = 'super_export.xlsx'
            document.body.appendChild(tagLink)
            tagLink.click()
            document.body.removeChild(tagLink)

            URL.revokeObjectURL(downloadUrl)
        } catch (error) {
            console.error('Ошибка при загрузке файла:', error)
        }
    }

    return (
        <div className={styles.notification}>
            <div className={styles.content}>
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.15" width="30" height="30" rx="15" fill="#4ECF81" />
                    <path
                        d="M12.9375 19.7142L8.8125 15.5892L9.99083 14.4108L13.0358 17.4525L12.9375 17.3567L20.0083 10.2858L21.1867 11.4642L14.1158 18.5358L12.9383 19.7133L12.9375 19.7142Z"
                        fill="#4ECF81"
                    />
                </svg>
                <Flex margin="0 0 0 10px" column>
                    <span>{t(message)}</span>
                    {link && (
                        <div onClick={handleDownload} className={styles.link}>
                            {t('common.download_report')}
                        </div>
                    )}
                </Flex>
            </div>
            <button
                className={styles.closeButton}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    onClose && onClose(e)
                }}
            >
                &times;
            </button>
        </div>
    )
}
