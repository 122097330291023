import React, { FC } from 'react'
import cn from './download-modal.module.scss'
import Icons from '@/components/Icons'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { ReactComponent as Download } from '@/pages/Lists/icons/download.svg'
import Button from '@/components/Button'
import { useTranslation } from 'react-i18next'

interface DownloadModalProps {
    isOpen: boolean
    onClose: () => void
    style?: React.CSSProperties
    children?: React.ReactNode
    callback?: () => void
}

const defaultStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 460,
    zIndex: 2000,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

export const DownloadModal: FC<DownloadModalProps> = ({
    isOpen,
    onClose,
    style = defaultStyle,
    children,
    callback,
}) => {
    const { t } = useTranslation()

    const closeHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        onClose()
    }
    const callbackHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        callback && callback()
    }

    return (
        <>
            {isOpen && (
                <div className={cn.root} onClick={closeHandler}>
                    <div className={cn.popup}>
                        <Flex content="space-between" onClick={closeHandler}>
                            <Download />
                            <Icons name="close" className={cn.close} />
                        </Flex>
                        <Flex column>
                            <Text margin="0 0 16px 0" semibold fSize="20px" color="gray-new-1" component="h3">
                                {t('common.report_is_being_created')}
                            </Text>
                            <Text fSize="14px" color="gray-new-1">
                                {t('common.usually_takes_5_minutes')}
                            </Text>
                        </Flex>
                        <Flex>
                            <Button onClick={callbackHandler} width="100%" variant="primary">
                                {t('common.go_to_downloads')}
                            </Button>
                        </Flex>
                    </div>
                </div>
            )}
        </>
    )
}

export default DownloadModal
