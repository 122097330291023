import { useEffect, useRef, useState } from 'react'
import useToken from '@/_helpers/Hooks/useToken'
import { showNotification } from '@/components/NotificationService/notificationService'

interface WebSocketData {
    type: string
    data?: {
        id: number
        url: string
        owner_id: number
        status: string
        name_of_list: string
        unloading_place: string
        count_of_bloggers_in_report: number
        created_at: string
        updated_at: string
    }
}

export const useWebSocket = () => {
    const token = useToken()
    const [shouldReconnect, setShouldReconnect] = useState(true) // Управление переподключением
    const socketRef = useRef<WebSocket | null>(null)

    // Домен для WebSocket
    const apiDomain = process.env.REACT_APP_WSS_DOMAIN || 'wss://api.yolotech.ru/'
    const socketUrl = `${apiDomain}wss/notification/reports?auth_token=${token}`

    const generateReportDownloadUrl = (websocketData: WebSocketData) => {
        if (!websocketData || websocketData.type !== 'async_set_report' || !websocketData.data) {
            return
        }

        const { url, unloading_place } = websocketData.data

        if (!url || !unloading_place) {
            return
        }

        const filename = url.split('/').pop()
        if (!filename) {
            return
        }

        return `/api/reports/download?filename=${encodeURIComponent(filename)}&unloading_place=${unloading_place}`
    }

    useEffect(() => {
        if (!shouldReconnect || !token) return

        let isUnmounted = false
        let reconnectAttempts = 0
        const maxReconnectAttempts = 5

        const connectWebSocket = () => {
            if (isUnmounted) return

            if (reconnectAttempts >= maxReconnectAttempts) {
                console.error('Превышено количество попыток переподключения WebSocket')
                setShouldReconnect(false)
                return
            }
            reconnectAttempts += 1

            const socket = new WebSocket(socketUrl)
            socketRef.current = socket

            socket.onopen = () => {
                console.log('WebSocket подключен!')
                reconnectAttempts = 0 // Сброс счётчика при успешном подключении
            }

            socket.onclose = (event) => {
                if (!event.wasClean) {
                    console.error(`WebSocket закрылся с ошибкой, код: ${event.code}`)
                } else {
                    console.log(`WebSocket закрыт cleanly, код: ${event.code}, причина: ${event.reason}`)
                }

                if (!isUnmounted) {
                    console.log('Попытка переподключения через 5 секунд...')
                    setTimeout(() => setShouldReconnect(true), 5000)
                }
            }

            socket.onmessage = (event) => {
                try {
                    const data: WebSocketData = JSON.parse(event.data)
                    if (data?.data?.url) {
                        const downloadUrl = generateReportDownloadUrl(data)
                        showNotification('common.report_is_ready', downloadUrl)
                    }
                } catch (err) {
                    console.error('Ошибка обработки сообщения WebSocket:', err)
                }
            }

            socket.onerror = (err) => {
                console.error('Ошибка WebSocket:', err)
            }
        }

        connectWebSocket()

        return () => {
            isUnmounted = true
            socketRef.current?.close()
        }
    }, [shouldReconnect, token, socketUrl])

    return null
}
