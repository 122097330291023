import React from 'react'
import { Notification } from './Notification/Notification'
import styles from './notificationManager.module.scss'
import { useNotificationState } from '@/components/NotificationService/notificationService'

export const NotificationManager = () => {
    const notifications = useNotificationState()

    return (
        <div className={styles.notificationContainer}>
            {notifications.map((notification) => (
                <Notification key={notification.id} {...notification} onClose={notification.onClose} />
            ))}
        </div>
    )
}
