import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import Icons from '@/components/Icons'
import useToken from '@/_helpers/Hooks/useToken'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { RootStore } from '@/_helpers/StoreType'
import PageLayout from '@/Layouts/PageLayout'
import useClass from '@/_helpers/Hooks/useClass'
import Flex from '@/_yc/Flex'

import { PageHeader } from './components/PageHeader'
import GetBloggers from './getBloggers'
import GetRecentSearches from './getRecentSearches'
import ListHeader from '../components/ListHeader/ListHeader'
import {
    setActiveList,
    setBloggersAndChannelsDefault,
    //  delList, renameList
} from '../model/actions'
import { CreateReportReq, getCustomListReqInfo, getFavoritesListReq } from '../model/service'
import { ICustomListInfo } from '../model/types'
import ListItem from './components/ListItem'
import MobileListItem from './components/ListItem/MobileListItem'
import ErrorPage from '../components/ErrorPage'
import EmptyPage from './components/EmptyPage'
import LoadingFiller from '../components/LoadingFiller'
import FetchCustomLists from '../CatalogOfLists/FetchCustomLists'

import cn from './ListOfBloggers.module.scss'
import DownloadModal from '@/pages/Lists/components/DownloadModal'

const ListOfBloggers = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const token = useToken()
    const dispatch = useDispatch()
    const root = useClass(cn.root)
    const { pathname } = useLocation()
    const [listInfo, setListInfo] = useState<ICustomListInfo | undefined>()
    const recentsCount = useSelector((store: RootStore) => store.AudienceInsights.RecentsCount)
    const [sort, setSort] = useState<boolean>(false)
    const [emptyFav, setEmptyFav] = useState<boolean>(false)
    const [show, setShow] = useState(false)
    FetchCustomLists()

    let status = { loading: true, error: false }

    const activeListId = useSelector((store: RootStore) => store.AudienceInsights.ActiveListId)
    const id = pathname.split('/')[2]
    const tempActiveListId =
        id.toLocaleLowerCase() === 'recent' ? -1 : id.toLocaleLowerCase() === 'favorites' ? -2 : parseInt(id)
    //get ID of LIKES list
    const getFavoritesID = useCallback(() => {
        if (!token) {
            return
        }
        getFavoritesListReq(token)
            .then((res) => {
                if (res && res.results && res.results.length > 0) dispatch(setActiveList(res.results[0].id))
                else {
                    dispatch(setActiveList(-2))
                    setEmptyFav(true)
                }
            })
            .catch((er) => {
                console.log(er)
            })
    }, [dispatch, token])
    //set current active list ID
    useEffect(() => {
        if (tempActiveListId === -2) {
            getFavoritesID()
        } else {
            // -1 or other ID
            dispatch(setActiveList(tempActiveListId))
        }
    }, [tempActiveListId, dispatch, getFavoritesID])

    //get bloggers for recent or custom list
    if (tempActiveListId === -1) {
        status = GetRecentSearches()
    } else {
        status = GetBloggers(sort ? 'sort=followers' : undefined)
    }

    //get info about list (name, comment etc)
    useEffect(() => {
        if (!token) return
        if (activeListId === -2) {
            setListInfo({
                id: -2,
                name: 'Likes',
                qty: 0,
                comment: null,
                modified_at: '',
                tags: [],
                influencers: [],
            })
            return
        }
        if (activeListId === -1) {
            setListInfo({
                id: -1,
                name: t('aud_insides.lists.recent_searches'),
                qty: recentsCount,
                comment: null,
                modified_at: '',
                tags: [],
                influencers: [],
            })
            return
        }
        getCustomListReqInfo(token, activeListId).then((res) => {
            setListInfo(res)
        })
    }, [token, activeListId, recentsCount, t, tempActiveListId, emptyFav])

    const data = useSelector((store: RootStore) => store.AudienceInsights.BloggersAndChannels)
    const grid = '3% 29% 10% 10% 10% 10% 18% 10%'

    const headerTextAr: string[] = [
        'aud_insides.grid_header.username',
        'aud_insides.grid_header.followers',
        'aud_insides.grid_header.erEN',
        'aud_insides.grid_header.topic',
        'aud_insides.grid_header.YoScore',
        '',
    ]

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'
    useEffect(() => {
        return () => {
            dispatch(setBloggersAndChannelsDefault([]))
        }
    }, [dispatch])

    const unmountID = useCallback(() => {
        dispatch(setActiveList(-10)) //costyl, po khoroshemu nuzhno delat undefined, no zavisimostey mnogo
    }, [dispatch])

    const callback = useCallback(() => {
        setShow(false)
        history.push('/audience/downloads')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setShow])

    const createReportHandler = useCallback(async () => {
        setShow(true)
        await CreateReportReq(token as string, activeListId, true)
    }, [token, activeListId])

    useEffect(() => () => unmountID(), [unmountID])
    const windowHeight = window.innerHeight
    return (
        <>
            <PageLayout color={'common-background'} className={root} title={t('titles.t2')}>
                <div className={cn.layout}>
                    <PageHeader handler={createReportHandler} data={!status.loading ? listInfo : undefined} />
                    <DownloadModal onClose={() => setShow(false)} isOpen={show} callback={callback} />
                    <ListHeader isRecent={false} grid={grid} texts={headerTextAr}>
                        {tempActiveListId !== -1 && (
                            <Flex align={'center'}>
                                <p className={cn[`sort${sort ? '-active' : ''}`]} onClick={() => setSort(!sort)}>
                                    Followers
                                </p>
                                <Icons name="sorter" className={sort ? cn['sort-active-svg'] : ''} />
                            </Flex>
                        )}
                    </ListHeader>
                    <div id={'listItemsID'} style={windowHeight < 1000 ? {} : { maxHeight: '749px', overflow: 'auto' }}>
                        {status.error && <ErrorPage />}

                        {data.length > 0 && !status.error && (
                            <Flex column>
                                {data.map((item, i) =>
                                    isMobile ? (
                                        <MobileListItem
                                            item={item}
                                            index={i + 1}
                                            key={item.id + item.userName + 'listMOBILE' + i}
                                        />
                                    ) : (
                                        <ListItem
                                            activeListId={activeListId}
                                            item={item}
                                            index={i + 1}
                                            grid={grid}
                                            key={item.id + item.userName + 'list' + i}
                                        />
                                    )
                                )}
                            </Flex>
                        )}

                        {(emptyFav || (data.length === 0 && !status.error && !status.loading)) && <EmptyPage />}
                        {!emptyFav && status.loading && (
                            <>
                                <LoadingFiller grid="3% 29% 68%" />
                            </>
                        )}
                    </div>
                </div>
            </PageLayout>
        </>
    )
}
export default ListOfBloggers
