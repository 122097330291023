import React, { FC, useEffect } from 'react'
import { PopupModal } from 'react-calendly'
import ym from 'react-yandex-metrika'
interface ICalendlyWidget {
    isOpen: boolean
    close: VoidFunction
}

const CalendlyWidget: FC<ICalendlyWidget> = ({ isOpen, close }) => {
    const windowRef = document.getElementById('root')

    const handleScheduleEvent = () => {
        ym('reachGoal', 'plan_meeting')
    }

    useEffect(() => {
        if (!isOpen) return

        const onMessage = (event: MessageEvent) => {
            if (event.origin === 'https://calendly.com' && event.data.event === 'calendly.event_scheduled') {
                handleScheduleEvent()
            }
        }

        window.addEventListener('message', onMessage)

        return () => {
            window.removeEventListener('message', onMessage)
        }
    }, [isOpen])

    if (!windowRef) return null

    return (
        <PopupModal
            url="https://calendly.com/yoloco-demo/30min?hide_gdpr_banner=1"
            onModalClose={close}
            open={isOpen}
            rootElement={windowRef!}
        />
    )
}
export default CalendlyWidget
//Schedule Event
