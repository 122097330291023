import useToken from '@/_helpers/Hooks/useToken'
import { RootStore } from '@/_helpers/StoreType'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage, addUnread, updateLastMessage } from '../Model/actions'
import { parseChatMessage } from './webSocketUtils'
import notif from '@/components/Notifier/model/actions.js'
import { useSpaceTranslation } from '@/_helpers'

export const OutreachListener = () => {
    const ws = useRef<WebSocket>()
    const token = useToken()
    const dispatch = useDispatch()
    const t = useSpaceTranslation('outreach.messenger.listener')

    const curChat = useSelector((store: RootStore) => store.Outreach.currentChat)

    const dispatchMessages = useCallback(
        (event) => {
            console.log(event)
            if (event.type !== 'outreach_message') return

            if (event.chatId === curChat && event.sender !== 'user') {
                dispatch(
                    addMessage({
                        sender: event.sender,
                        message: event.message,
                        time: event.time,
                        id: event.id,
                        isRead: false,
                    })
                )
                dispatch(updateLastMessage({ chatId: event.chatId, message: event.message, time: event.time }))
            } else if (event.sender !== 'user') {
                dispatch(notif.success(`${t('new_message')} ${event.bloggerName ? event.bloggerName : ''}`))
                dispatch(addUnread(event.chatId))
                dispatch(updateLastMessage({ chatId: event.chatId, message: event.message, time: event.time }))
            } else if (event.sender === 'user') {
                dispatch(updateLastMessage({ chatId: event.chatId, message: event.message, time: event.time }))
            }
        },
        [curChat, dispatch, t]
    )

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_WSS_DOMAIN || 'wss://api.yolotech.ru/'
        ws.current = new WebSocket(`${baseUrl}wss/events?auth_token=${token}`)
        ws.current.onopen = () => console.log('ws opened')
        ws.current.onclose = () => console.log('ws closed')

        const wsCurrent = ws.current

        return () => {
            wsCurrent.close()
        }
    }, [token])

    useEffect(() => {
        if (!ws.current) return

        ws.current.onmessage = (e) => {
            const message = JSON.parse(e.data)
            const parsed = parseChatMessage(message)
            dispatchMessages(parsed)
        }
    }, [dispatchMessages])

    return <></>
}
