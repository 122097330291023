import React, { useState } from 'react'

interface NotificationProps {
    id: number
    message: string
    link?: string
    onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

let notifications: NotificationProps[] = []
let setNotifications: React.Dispatch<React.SetStateAction<NotificationProps[]>>

export const useNotificationState = () => {
    const [state, setState] = useState<NotificationProps[]>([])
    notifications = state
    setNotifications = setState
    return state
}

export const showNotification = (message: string, link?: string) => {
    const id = Date.now()
    const newNotification: NotificationProps = {
        id,
        message,
        link,
        onClose: (e) => {
            e.stopPropagation()
            removeNotification(id)
        },
    }

    notifications.push(newNotification)
    setNotifications([...notifications])

    // setTimeout(() => removeNotification(id), 10000)
}

export const removeNotification = (id: number) => {
    notifications = notifications.filter((n) => n.id !== id)
    setNotifications([...notifications])
}
