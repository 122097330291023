import React, { FC } from 'react'
import cn from './searchBar.module.scss'
import { Flex } from '@/_yc'
import SearchIcon from '@/components/Icons/UI/Search'
import { useTranslation } from 'react-i18next'

interface SearchBarProps {
    onSearch: (query: string) => void
}

const SearchBar: FC<SearchBarProps> = ({ onSearch }) => {
    const { t } = useTranslation()
    return (
        <Flex className={cn['searchBar']}>
            <input
                type="text"
                placeholder={t('common.search_by_name')}
                className={cn['searchInput']}
                onChange={(e) => onSearch(e.target.value)}
            />
            <SearchIcon />
        </Flex>
    )
}

export default SearchBar
