import React, { useState, useEffect } from 'react'
import styles from './custom-accordion.module.scss'
import { Flex } from '@/_yc'
import { ReactComponent as PlusIcon } from '../../icons/plus-circle.svg'
import { ReactComponent as MinusIcon } from '../../icons/minus-circle.svg'
import { Category } from '@/pages/TargetAudience/services/types'
import CustomCheckbox from '@/components/CustomCheckbox'

interface Option {
    label: string
    value: string
}

interface CustomAccordionProps {
    categories: Category[]
    initialSelectedOptions: Category[]
    onChange: (selected: Category[]) => void
}

const CustomAccordion: React.FC<CustomAccordionProps> = ({ categories, initialSelectedOptions, onChange }) => {
    const [expandedCategory, setExpandedCategory] = useState<string | null>(null)
    const [selectedOptions, setSelectedOptions] = useState<Category[]>(initialSelectedOptions)

    useEffect(() => {
        onChange(selectedOptions)
        // eslint-disable-next-line
    }, [selectedOptions])

    const handleCategoryToggle = (categoryType: string) => {
        setExpandedCategory(expandedCategory === categoryType ? null : categoryType)
    }

    const handleOptionChange = (categoryType: string, option: Option, title: string) => {
        const categoryIndex = selectedOptions.findIndex((cat) => cat.type === categoryType)

        if (categoryIndex !== -1) {
            const category = selectedOptions[categoryIndex]
            const isSelected = category.values.some((opt) => opt.value === option.value)

            const updatedValues = isSelected
                ? category.values.filter((opt) => opt.value !== option.value) // Удаляем опцию
                : [...category.values, option] // Добавляем опцию

            const updatedCategory = { ...category, values: updatedValues }

            const updatedSelectedOptions = [
                ...selectedOptions.slice(0, categoryIndex),
                updatedCategory,
                ...selectedOptions.slice(categoryIndex + 1),
            ].filter((cat) => cat.values.length > 0) // Удаляем пустые категории

            setSelectedOptions(updatedSelectedOptions)
        } else {
            const newCategory = { type: categoryType, title, values: [option] }
            setSelectedOptions([...selectedOptions, newCategory])
        }
    }

    return (
        <div className={styles.accordionContainer}>
            {categories.map((category) => (
                <div key={category.type} className={styles.accordion}>
                    <div className={styles.accordionSummary} onClick={() => handleCategoryToggle(category.type)}>
                        <span className={styles.accordionTitle}>{category.title}</span>
                        <button className={styles.toggleButton}>
                            {expandedCategory === category.type ? <MinusIcon /> : <PlusIcon />}
                        </button>
                    </div>
                    {expandedCategory === category.type && (
                        <div className={styles.accordionDetails}>
                            {category.values.map((option) => {
                                const isChecked =
                                    selectedOptions
                                        .find((cat) => cat.type === category.type)
                                        ?.values.some((opt) => opt.value === option.value) || false
                                return (
                                    <Flex align="center" key={option.value}>
                                        <CustomCheckbox
                                            isChecked={isChecked}
                                            onChange={() => handleOptionChange(category.type, option, category.title)}
                                        />
                                        <label className={`${styles.option} ${isChecked ? styles.checked : ''}`}>
                                            {option.label}
                                        </label>
                                    </Flex>
                                )
                            })}
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default CustomAccordion
