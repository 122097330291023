import React, { FC } from 'react'
import cn from './list-table.module.scss'
import ListTableRow from '@/pages/Lists/ListDownloads/components/ListTableRow'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import CustomCheckbox from '@/components/CustomCheckbox'
import Loader from '@/components/Loader'
import { ListItem } from '@/pages/Lists/model/types'
import { useTranslation } from 'react-i18next'

interface ListTableProps {
    items: ListItem[]
    onSelectItem: (id: number | 'all', checked: boolean) => void
    selectedItems: number[]
    isLoading: boolean
}

const ListTable: FC<ListTableProps> = ({ items, onSelectItem, selectedItems, isLoading }) => {
    const { t } = useTranslation()

    return (
        <div className={cn['list-table']}>
            <div className={cn['header']}>
                <Flex margin="0 0 0 2px">
                    <CustomCheckbox
                        isChecked={selectedItems.length === items.length}
                        onChange={(checked) => onSelectItem('all', checked)}
                    />
                </Flex>
                <Text fSize="10px" semibold color={'gray-new-3'}>
                    {t('table_headers.list_name')}
                </Text>
                <Text fSize="10px" semibold color={'gray-new-3'}>
                    {t('table_headers.creation_date')}
                </Text>
                <Text fSize="10px" semibold color={'gray-new-3'}>
                    {t('table_headers.status')}
                </Text>
            </div>
            {items.map((item) => (
                <ListTableRow selectedItems={selectedItems} key={item.id} item={item} onSelect={onSelectItem} />
            ))}
            {isLoading && <Loader style={{ margin: 'auto' }} />}
        </div>
    )
}

export default ListTable
