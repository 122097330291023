import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// import { MassParsingPopup } from '@/components/MassParsing/MassParsingPopup'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
// import useOpenClose from '@/_helpers/useOpenClose'
import PageTitle from '@/components/PageTitle'
// import Button from '@/components/Button'
import Flex from '@/_yc/Flex'
import MassParsingNEW from '@/components/MassParsingNEW'

import CreateNewList from '../../../components/CreateNewList'

import cn from './PageHeader.module.scss'

export const PageHeader = () => {
    const { t } = useTranslation()
    // const [isMassPopupOpen, openMassPopup, closeMassPopup] = useOpenClose()

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    const linkToHelper = useSelector<any, string>((state) => state.Commercial_.value.hp_ainsights)

    return (
        <>
            <Flex className={cn.header}>
                <PageTitle
                    title={t('aud_insides.title')}
                    linkToHelper={linkToHelper}
                    icon="lists"
                    marginBottom="0"
                    iconSize={isMobile ? '20px' : '25px'}
                />
                {/* <p className={cn.description}>{t('aud_insides.description')}</p> */}

                <Flex className={cn.btns}>
                    <MassParsingNEW />
                    {/* <Button className={cn.add} onClick={openMassPopup}>
                        {t('aud_insides.search_input.mass_add')}
                    </Button> */}
                    <CreateNewList />
                </Flex>
                {/* {isMassPopupOpen && <MassParsingPopup isOpen={isMassPopupOpen} close={closeMassPopup} />} */}
            </Flex>
        </>
    )
}
