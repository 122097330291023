import Icons from '@/components/Icons'
import React, { ChangeEvent, FC, useCallback, useRef, useState } from 'react'
import cn from './ValueInput.module.scss'
import { Currency } from '../../../utils/types'

interface IValueInput {
    value: number | null
    valueKey: string
    onSubmit: (bloggerData: { [key: string]: number | null }) => void
    currency?: Currency
}

export const ValueInput: FC<IValueInput> = ({ value, onSubmit, valueKey, currency }) => {
    const [isFocused, setIsFocused] = useState(false)
    const [inputVal, setInputValue] = useState(value)
    const ref = useRef<null | HTMLInputElement>(null)

    const edit = useCallback(() => {
        setIsFocused(true)
    }, [setIsFocused])

    const done = useCallback(() => {
        setIsFocused(false)
        onSubmit && valueKey && onSubmit({ [valueKey]: inputVal })
    }, [setIsFocused, inputVal, valueKey, onSubmit])

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const re = /^[0-9\b]+$/

            if (e.target.value === '') {
                setInputValue(null)
                return
            }

            if (re.test(e.target.value)) {
                setInputValue(parseInt(e.target.value))
            }
        },
        [setInputValue]
    )

    return (
        <div className={cn.priceInput}>
            {currency && <p className={cn.currency}>{currency}</p>}
            <input
                ref={ref}
                value={inputVal || undefined}
                placeholder={'Enter'}
                type={'number'}
                onFocus={edit}
                onBlur={done}
                onChange={onChange}
            />

            {!isFocused && !value && !inputVal && (
                <div
                    onClick={() => {
                        setIsFocused((p) => !p)
                        ref?.current?.focus()
                    }}
                    className={cn.editIcon}
                >
                    <Icons name={'edit'} />
                </div>
            )}

            {isFocused && (
                <div className={cn.doneButton} onClick={done}>
                    <Icons name={'done'} />
                </div>
            )}
            {isFocused && (
                <div className={cn.crossButton}>
                    <Icons name={'close--small'} />
                </div>
            )}
        </div>
    )
}
