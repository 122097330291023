import React, { FC, useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Avatar from '@/components/Avatar'
import Flex from '@/_yc/Flex'
import Icons from '@/components/Icons'
import MultiColorIcon, { colors } from '@/components/MultiColorIcon'
import * as Routs from '@/routs.js'
import BloggerLink from '@/components/Share/BloggerLink'
import { RootStore } from '@/_helpers/StoreType'
import { Platform } from '@/pages/Reports/types'
import useOpenClose from '@/_helpers/useOpenClose'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import useToken from '@/_helpers/Hooks/useToken'
import notif from '@/components/Notifier/model/actions.js'
import { MassAddCheckBoxNEW } from '@/components/MassAddToList'
import { Comment, NewComment } from '@/components/Comment/Comment'

import { ICustomListInfo, IChannel, IBlogger } from '../../../model/types'
import Tags from '../../components/Tags'
import { CreateReportReq, EditCustomListReq } from '../../../model/service'

import cn from './ListItem.module.scss'
import Button from '@/components/Button'

interface IListItem {
    data: ICustomListInfo
    grid: string
    i: number
    openModal?: () => void
}
const ListItem: FC<IListItem> = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const user = useSelector((store: RootStore) => store.authentication.user?.profile)
    const token = useToken()

    const creator = useMemo(() => {
        if (user?.first_name === '' && user?.last_name === '') return user.email
        return `${user?.first_name || ''} ${user?.last_name || ''}`
    }, [user])

    const getAvatar = (b: { channel?: IChannel; blogger?: IBlogger }) => {
        if (b.blogger?.avatar && b.blogger.avatar.length !== 0) {
            return b.blogger.avatar
        }
        if (b.channel?.avatar) return b.channel.avatar
        return ''
    }

    //comment
    const [comment, setComment] = useState<string>(props.data.comment || '')
    const [isOpen, open, close] = useOpenClose()
    const handleOpen = (e: any) => {
        e.preventDefault()
        open()
    }
    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    const commit = (commentValue: string) => {
        if (!token) return
        EditCustomListReq(token, props.data.name, props.data.id, commentValue)
            .then(() => {
                setComment(commentValue)
                dispatch(notif.success(t('aud_insides.status.success')))
            })
            .catch(() => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })

        close()
    }
    const createReportHandler = useCallback(async () => {
        props.openModal && props.openModal()
        await CreateReportReq(token as string, props.data.id, true)
    }, [props, token])

    return (
        <>
            <Flex column className={cn.item}>
                <div style={{ display: 'grid', gridTemplateColumns: props.grid }} className={cn['item-grid']}>
                    <Flex align="center" margin="0 10px 0 0" className={cn['hide-mobile']}>
                        <MassAddCheckBoxNEW
                            id={props.data.id.toString()}
                            type={'lists'}
                            listData={props.data}
                            key={props.data.id}
                            disabled={false}
                            index={props.i + 1}
                        />
                    </Flex>

                    <Link to={`${Routs.LISTS}/${props.data.id}`}>
                        <Flex>
                            <MultiColorIcon color={colors[props.i % colors.length]}>
                                <Icons name={'lists'} className={cn.icon} />
                            </MultiColorIcon>

                            <Flex column content="space-between" className={cn['name--container']} margin="0 0 0 16px">
                                <Flex align="center">
                                    <p className={cn.name}>{props.data.name}</p>
                                    {!isMobile && comment.length === 0 && (
                                        <Flex className={cn['new-comment']}>
                                            <NewComment handleOpen={handleOpen} />
                                        </Flex>
                                    )}
                                </Flex>
                                <p className={cn['name-sub']}>
                                    {props.data.qty} {t('aud_insides.lists.influencers')}
                                </p>
                            </Flex>
                        </Flex>
                    </Link>

                    <Tags
                        tags={props.data.tags}
                        id={props.data.id}
                        name={props.data.name}
                        className={cn['tag-hover']}
                    />

                    <p className={cn.cell}>{creator}</p>
                    <p className={cn.cell}>{moment(props.data.modified_at).format('DD.MM.YYYY')}</p>

                    <Flex className={cn.avatars}>
                        {props.data.influencers &&
                            props.data.influencers.map((b, i) => (
                                <>
                                    <BloggerLink
                                        key={i}
                                        id={b.blogger?.external_id || b.channel_id || b.twitch_channel_id || ''}
                                        newTab
                                        platform={b.channel_id ? Platform.YouTube : Platform.Instagram}
                                        disabled={!b.blogger?.external_id && !b.channel_id && !b.twitch_channel_id}
                                    >
                                        <div className={cn.avatarContainer}>
                                            <Avatar
                                                alt="AVA"
                                                src={getAvatar(b)}
                                                aria-label="Аватарка"
                                                className={cn.avatar}
                                                loadingAvatarWidth="40"
                                                style={
                                                    !b.blogger?.external_id && !b.channel_id && !b.twitch_channel_id
                                                        ? { cursor: 'default' }
                                                        : {}
                                                }
                                            />
                                        </div>
                                    </BloggerLink>
                                </>
                            ))}
                        {props.data.influencers && props.data.influencers.length === 6 && (
                            <Flex className={cn['avatar-dots']} align="center" content="center">
                                &bull;&bull;&bull;
                            </Flex>
                        )}
                    </Flex>
                    <Flex align="center">
                        <Button onClick={createReportHandler} className={cn['download']} size="small">
                            Download
                        </Button>
                    </Flex>
                    {!isMobile && (
                        <Comment
                            close={close}
                            open={open}
                            isOpen={isOpen}
                            value={comment || undefined}
                            setValue={setComment}
                            style={{ gridColumn: '2/7', marginLeft: '56px' }}
                            submit={commit}
                        />
                    )}
                </div>
            </Flex>
        </>
    )
}

export default ListItem
