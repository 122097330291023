import React, { FC } from 'react'
import cn from './pagination-button.module.scss'

interface PaginationButtonProps {
    onClick: () => void
}

const PaginationButton: FC<PaginationButtonProps> = ({ onClick }) => (
    <button className={cn['pagination-button']} onClick={onClick}>
        Показать ещё
        <span>+</span>
    </button>
)

export default PaginationButton
