import React, { FC } from 'react'
import cn from './list-table.module.scss'
import Text from '@/components/Text'
import DownloadIcon from '@/components/Icons/UI/Download'
import { Flex } from '@/_yc'
import Icons from '@/components/Icons'
import CustomCheckbox from '@/components/CustomCheckbox'
import { ListItem } from '@/pages/Lists/model/types'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import useToken from '@/_helpers/Hooks/useToken'

interface ListTableRowProps {
    item: ListItem
    onSelect: (id: number | 'all', checked: boolean) => void
    selectedItems: number[]
}

const ListTableRow: FC<ListTableRowProps> = ({ item, onSelect, selectedItems }) => {
    const { t } = useTranslation()
    const token = useToken()

    const filename = item?.url?.split('/')?.pop()
    const url = `/api/reports/download?filename=${encodeURIComponent(filename as string)}&unloading_place=${
        item?.unloading_place
    }`

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}${url as string}`, {
                responseType: 'blob',
                headers: token ? { Authorization: `Token ${token}` } : {},
            })
            const blob = new Blob([response.data], { type: response.headers['content-type'] })
            const downloadUrl = URL.createObjectURL(blob)

            const tagLink = document.createElement('a')
            tagLink.href = downloadUrl
            tagLink.download = 'super_export.xlsx'
            document.body.appendChild(tagLink)
            tagLink.click()
            document.body.removeChild(tagLink)

            URL.revokeObjectURL(downloadUrl)
        } catch (error) {
            console.error('Ошибка при загрузке файла:', error)
        }
    }

    return (
        <div className={cn['row']}>
            <Flex>
                <CustomCheckbox
                    isChecked={selectedItems.includes(item.id)}
                    onChange={(checked) => onSelect(item.id, checked)}
                />
            </Flex>
            <Flex>
                <Flex className={`${cn['icon-wrap']} ${item.status === 'Готово' ? cn['ready'] : cn['process']}`}>
                    <Icons name={'lists'} className={cn.icon} />
                </Flex>
                <Flex column>
                    <Text fSize="14px" semibold color={'gray-new-1'}>
                        {item.name_of_list}
                    </Text>
                    <Text fSize="12px" semibold color={'gray-new-3'}>
                        {item.count_of_bloggers_in_report} influencers
                    </Text>
                </Flex>
            </Flex>

            <Text fSize="14px" color={'gray-new-1'}>
                {new Date(item.created_at).toLocaleDateString()}
            </Text>
            <Text
                fSize="10px"
                component="div"
                className={`${cn['status']}
             ${item.status === 'REPORT_IS_DONE' ? cn['green'] : cn['gray']}`}
            >
                {item.status === 'REPORT_IS_DONE' ? t('download_list.ready') : t('download_list.processing')}
            </Text>
            <button onClick={handleDownload} className={cn['downloadBtn']} disabled={item.status !== 'REPORT_IS_DONE'}>
                {t('common.download')}
                <DownloadIcon style={{ marginLeft: '4px' }} />
            </button>
        </div>
    )
}

export default ListTableRow
