import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import { EmptyState } from './EmptyState/EmptyState'
import { ScrollableTable } from '@/pages/CampaignManager/Campaign/Tabs/Influencers/InfluencersTable/ScrollableTable/ScrollableTable'
import { Header } from './Header/Header'
import { ContentType, Currency, GetColumns, MediaPlanBlogger } from '../utils/types'
import { Row } from './Row/Row'
import { useSpaceTranslation } from '@/_helpers'
import cn from './ConfigurableTable.module.scss'
import { CellType } from './Row/Cell/Cell'
import { useQuery } from '@apollo/client'
import { CampaignManagerContext } from '@/pages/CampaignManager/CampaignManagerContext/CampaignManagerContext'
import { useCampaignId } from '@/_helpers/Hooks/useCampaignId'
import { GET_CUSTOM_COLUMNS } from '../utils/queries'
import LoadingFiller from '@/pages/Lists/components/LoadingFiller'

interface ConfigurableTableProps {
    rows: MediaPlanBlogger[]
    isPlanLoading: boolean
    unlockedAccounts: string[]
    contentTypes: ContentType[]
    refetchBloggers: () => void
    currency: Currency
}

export interface Config {
    columns: {
        name: string
        key: string
        width: string
        active: boolean
        type: CellType
    }[]
}

export const ConfigurableTable: FC<ConfigurableTableProps> = ({
    rows,
    isPlanLoading,
    refetchBloggers,
    unlockedAccounts,
    contentTypes,
    currency,
}) => {
    const isEmpty = rows.length === 0 && !isPlanLoading
    const t = useSpaceTranslation('media_planner.plan.table.header')
    const standardWidth = '128px'

    const context = useContext(CampaignManagerContext)
    const id = useCampaignId()
    const {
        data,
        refetch: refetchColumns,
        loading,
        error,
    } = useQuery<GetColumns>(GET_CUSTOM_COLUMNS, {
        variables: {
            orderId: id,
        },
        context: context,
    })

    const defaultConfig = {
        columns: [
            {
                name: t('content_type'),
                key: 'contentType',
                width: standardWidth,
                active: true,
                type: CellType.ContentType,
            },
            {
                name: t('total_reach'),
                key: 'totalReach',
                width: standardWidth,
                active: true,
                type: CellType.LongNumInput,
            },
            {
                name: t('post_reach'),
                key: 'postsReach',
                width: standardWidth,
                active: false,
                type: CellType.ShortNumInput,
            },
            {
                name: t('stories_reach'),
                key: 'storiesReach',
                width: standardWidth,
                active: false,
                type: CellType.ShortNumInput,
            },
            {
                name: t('video_views'),
                key: 'videosViews',
                width: standardWidth,
                active: true,
                type: CellType.LongNumInput,
            },
            {
                name: t('total_cost'),
                key: 'totalCost',
                width: standardWidth,
                active: true,
                type: CellType.Money,
            },
            {
                name: t('cpv'),
                key: 'cpv',
                width: standardWidth,
                active: true,
                type: CellType.Float,
            },
            {
                name: t('followers'),
                key: 'data.metrics.followers',
                width: standardWidth,
                active: true,
                type: CellType.ShortNumValue,
            },
            {
                name: t('er'),
                key: 'data.metrics.er',
                width: standardWidth,
                active: true,
                type: CellType.Percent,
            },
            {
                name: t('yo_score'),
                key: 'data.blogger_rating.main_value',
                width: standardWidth,
                active: true,
                type: CellType.YoScore,
            },
            {
                name: t('man_share'),
                key: 'data.audience.man_share',
                width: standardWidth,
                active: true,
                type: CellType.String,
            },
            {
                name: t('woman_share'),
                key: 'data.audience.woman_share',
                width: standardWidth,
                active: true,
                type: CellType.String,
            },
            {
                name: '0-17',
                key: 'data.audience.ageGender.0-17.dist',
                width: standardWidth,
                active: true,
                type: CellType.Percent,
            },
            {
                name: '18-24',
                key: 'data.audience.ageGender.18-24.dist',
                width: standardWidth,
                active: true,
                type: CellType.Percent,
            },
            {
                name: '25-34',
                key: 'data.audience.ageGender.25-34.dist',
                width: standardWidth,
                active: true,
                type: CellType.Percent,
            },
            {
                name: '35-44',
                key: 'data.audience.ageGender.35-44.dist',
                width: standardWidth,
                active: true,
                type: CellType.Percent,
            },
            {
                name: '45-54',
                key: 'data.audience.ageGender.45-54.dist',
                width: standardWidth,
                active: true,
                type: CellType.Percent,
            },
            {
                name: '>54',
                key: 'data.audience.ageGender.>54.dist',
                width: standardWidth,
                active: true,
                type: CellType.Percent,
            },
            {
                name: t('countries'),
                key: 'data.audience.countries.country_name',
                width: standardWidth,
                active: true,
                type: CellType.String,
            },
            {
                name: t('languages'),
                key: 'data.audience.languages.language_name',
                width: standardWidth,
                active: true,
                type: CellType.String,
            },
        ],
    }
    const [config, setConfig] = useState<Config>(defaultConfig)
    console.log(rows, 'tows')

    useEffect(() => {
        if (!data || loading || error) {
            return
        }

        if (data.customColumn.length === 0) {
            return
        }
        const newConfig = { ...defaultConfig }
        // add custom columns to config
        data.customColumn.forEach((column) => {
            newConfig.columns.push({
                name: column.name,
                key: `customData.${column.key}`,
                width: standardWidth,
                active: true,
                type: CellType.CustomNumInput,
            })
        })

        setConfig(newConfig)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error])

    const onColumnToggle = useCallback(
        (key: string) => {
            const newConfig = { ...config }
            const column = newConfig.columns.find((column) => column.key === key)
            if (column) {
                column.active = !column.active

                const activeColumns = newConfig.columns.filter((column) => column.active)

                const newWidth = 896 / activeColumns.length

                const newColumns = newConfig.columns.map((column) => ({ ...column, width: `${newWidth}px` }))
                setConfig({ ...newConfig, columns: newColumns })
            }
        },
        [config]
    )

    if (isEmpty) {
        return <EmptyState />
    }

    return (
        <div style={{ position: 'relative' }}>
            <ScrollableTable>
                <div className={cn.table}>
                    <Header
                        config={config}
                        onColumnToggle={onColumnToggle}
                        refetchColumns={refetchColumns}
                        refetchBloggers={refetchBloggers}
                    />

                    {isPlanLoading && <LoadingFiller grid="3% 29% 68%" />}
                    {rows.map((row, i: number) => (
                        <Row
                            contentTypes={contentTypes}
                            blogger={row}
                            index={i}
                            key={row?.data?.info?.username}
                            config={config}
                            isUnlocked={unlockedAccounts.includes(row.externalId)}
                            currency={currency}
                        />
                    ))}
                </div>
            </ScrollableTable>
        </div>
    )
}
