import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Flex from '@/_yc/Flex'
import Heading from '@/components/Heading'
import Icons from '@/components/Icons'
import * as Routs from '@/routs.js'

import { ICustomListInfo } from '../../../model/types'

import cn from './PageHeader.module.scss'
import MassParsingNEW from '@/components/MassParsingNEW'
import Button from '@/components/Button'
import DownloadIcon from '@/components/Icons/UI/Download'

interface IPageHeader {
    data?: ICustomListInfo
    handler?: () => void
}
export const PageHeader: FC<IPageHeader> = (props) => {
    const { t } = useTranslation()

    return (
        <>
            <Flex style={{ padding: '108px 0 20px 0' }} grow content="space-between" className={cn.container}>
                <Heading type="h3" style={{ color: '#3D3F42', marginBottom: 0 }}>
                    <Flex>
                        <div className={cn.wrap}>
                            <Icons name={'lists'} className={cn.icon} style={{ width: '25px', height: '25px' }} />
                        </div>
                        <Flex column margin="0 0 0 20px" content="space-between">
                            <Flex align="center">
                                <Link to={Routs.LISTS}>
                                    <span className={cn.title}>{t('aud_insides.title')}</span>
                                </Link>
                                {props.data?.name && (
                                    <>
                                        <Icons
                                            name={'arrow-right'}
                                            className={cn.arrow}
                                            style={{ width: '15px', height: '25px', margin: '0 10px' }}
                                        />
                                        {props.data?.name}
                                    </>
                                )}
                            </Flex>
                            {props.data && (
                                <p className={cn.desc}>
                                    {props.data.qty} {' ' + t('aud_insides.send_to_mail.influencers')}
                                </p>
                            )}
                        </Flex>
                    </Flex>
                </Heading>

                <Flex className={cn.btns}>
                    {/*<DownloadListModal />*/}
                    <Button className={cn['btn']} margin="0 10px 0 0" variant={'outline'} onClick={props.handler}>
                        Download
                        <DownloadIcon style={{ marginLeft: '4px' }} />
                    </Button>
                    <MassParsingNEW />
                </Flex>

                {/* {isMassPopupOpen && <MassParsingPopup isOpen={isMassPopupOpen} close={closeMassPopup} />} */}
            </Flex>
        </>
    )
}
