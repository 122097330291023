import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { RootStore } from '@/_helpers/StoreType'
import Flex from '@/_yc/Flex'

import ListHeader from '../components/ListHeader/ListHeader'
import ListItem from './components/ListItem/ListItem'
import EmptyPage from './components/EmptyPage'
import ErrorPage from '../components/ErrorPage'
import PaginatedFetchLists from './PaginatedFetchLists'
import MobileListItem from './components/ListItem/MobileListItem'
import LoadingFiller from '../components/LoadingFiller'

interface ICatalogOfLists {
    activeTag?: string
    openModal?: () => void
}
const CatalogOfLists: FC<ICatalogOfLists> = (props) => {
    const status = PaginatedFetchLists(props.activeTag)
    const data = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)

    const error = useMemo(() => data.length === 0 && status.error, [status, data.length])

    const grid = '3% 20% 24% 14% 10% 20% 9%'

    const headerTextAr: string[] = [
        'aud_insides.grid_header.list_name',
        'aud_insides.grid_header.tags',
        'aud_insides.grid_header.creator',
        'aud_insides.grid_header.last_changes',
        'aud_insides.grid_header.influencers',
    ]
    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    const windowHeight = window.innerHeight

    return (
        <>
            <ListHeader isRecent={false} grid={grid} texts={headerTextAr} isCatalofOfLists={true} />

            {/* scroll for 4k displays */}
            <div id={'listItemsID'} style={windowHeight < 1129 ? {} : { maxHeight: '834px', overflow: 'auto' }}>
                {error && <ErrorPage />}
                {data.length > 0 && !error && (
                    <>
                        <Flex column>
                            {data.map((item, i) =>
                                isMobile ? (
                                    <MobileListItem data={item} i={i} key={i} />
                                ) : (
                                    <ListItem openModal={props.openModal} data={item} grid={grid} i={i} key={i} />
                                )
                            )}
                        </Flex>
                    </>
                )}
                {data.length === 0 && !status.loading && !error && (
                    <>
                        <EmptyPage />
                    </>
                )}

                {status.loading && (
                    <>
                        <LoadingFiller grid="3% 29% 68%" />
                    </>
                )}
            </div>
        </>
    )
}

export default CatalogOfLists
