import WebSocketService from '@/_helpers/WebSocketService'
import { parseNativeNotification } from '../parseNativeNotification'
import { Notification } from '../Types'
import { Subscription } from '@/_helpers/Observable'

type holder = null | {
    socket: WebSocketService<Notification>
    token: string
}

let saved: holder = null

/**
 *
 * @param token user token
 * @returns WebSocketService instance
 * @description works like singleton
 */
export const connect = (token: string, cb: (notif: Notification) => void): Subscription => {
    if (saved && saved.token === token) return saved.socket.subscribe(cb)

    if (saved) {
        saved.socket.close()
    }

    saved = {
        socket: new WebSocketService<Notification>(`wss/events?auth_token=${token}`, (data) => {
            try {
                const json = JSON.parse(data)
                return parseNativeNotification(json.event)
            } catch {
                return null
            }
        }),
        token,
    }

    return saved.socket.subscribe(cb)
}
