import { gql } from '@apollo/client'

export const CREATE_EMPTY_MEDIA_PLAN = gql`
    mutation CreateMediaPlan {
        createMediaPlanOrder(bloggers: [], name: "New planning", bloggersType: INSTAGRAM) {
            mediaPlanOrder {
                id
                name
                status
                data
                bloggers {
                    postCost
                    data
                }
                totalCost
            }
        }
    }
`

export const GET_MEDIA_PLANS_LIST = gql`
    query GetMediaPlans {
        mediaPlanOrder {
            name
            id
            status
            data
            modifiedDate
        }
    }
`

export const GET_MEDIA_PLANS_WITH_BLOGGERS_LIST = gql`
    query GetMediaPlans {
        mediaPlanOrder {
            name
            id
            status
            data
            modifiedDate
            bloggers {
                externalId
                mediaPlanId {
                    id
                }
                data
                postsReach
                videosViews
                storiesReach
                totalCost
                customData
                bloggerType
                contentType {
                    name
                    color
                    id
                }
                cpv
                totalReach
            }
        }
    }
`

export const DELETE_MEDIA_PLANS = gql`
    mutation DeleteMediaPlan($ids: [Int]!) {
        deleteMediaPlanOrders(ids: $ids) {
            __typename
        }
    }
`
